<template>
  <v-col>
    <data-table
      ref="table"
      :actions="actions"
    />
  </v-col>
</template>

<script>
import DataTable from 'src/tools/components/table/DataTable'

export default {
  name: 'Creatives',
  components: { DataTable },
  data() {
    return {
      actions: [],
    }
  },
}
</script>
